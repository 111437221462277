export default {
  metrics: {
    index: 'Métriques',

    program: 'Programme',
    system: 'Système',
    canals: 'Canaux'
  },

  administrators: {
    index: 'Administrateurs',
    list: 'Administrateurs'
  },

  users: {
    index: 'Utilisateurs',
    management: 'Gestion',
    fraud: 'Fraude',
    segments: 'Segments',
    transfers: 'Virements'
  },

  animation: {
    index: 'Animation',

    offers: 'Offres',
    messages: 'Messages',
    campaigns: 'Campagnes',
    statsPushes: {
      title: 'Statistiques',
      subtitle: 'des Pushs'
    }
  },

  programs: {
    index: 'Programme',

    rules: 'Règles',

    management: 'Gestion',
    classification: {
      title: 'Classification',
      subtitle: 'des Libellés'
    },
    classificationV2: {
      title: 'Classification',
      subtitle: 'des Libellés (v2)'
    }
  },

  sources: {
    index: 'Données Source',

    products: 'Produits',
    pointsOfSale: 'Points de Ventes',
    productsCollection: 'Collection de produits'
  }
}

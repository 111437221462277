export const setIsGalleryShown = (state, boolean) => {
  state.isGalleryShown = boolean
}
export const toggleGalleryColumn = (state) => {
  state.isGalleryShown = !state.isGalleryShown
}

export const setScrollContainer = (state, scrollContainer) => {
  state.scrollContainer = scrollContainer
}
export const setScrollAnchor = (state, scrollAnchor) => {
  state.scrollAnchor = scrollAnchor
}
export const setScrollingState = (state, boolean) => {
  state.isScrolling = boolean
}
export const setScrollingDoneState = (state, boolean) => {
  state.isScrollingDone = boolean
}

export const setMenuMinimizedState = (state, boolean) => {
  state.isMenuMinimized = boolean
}

export const openDeclarationHeaderRefunds = (state) => {
  state.isDeclarationHeaderRefundsOpen = true
}
export const toggleIsDeclarationHeaderRefundsOpen = (state) => {
  state.isDeclarationHeaderRefundsOpen = !state.isDeclarationHeaderRefundsOpen
}

export const toggleIsOpenForDeclarationHeaderTasksToDo = (state) => {
  state.isOpenDeclarationHeaderTasksToDo = !state.isOpenDeclarationHeaderTasksToDo
}

<template>
  <v-list class="pt-0" dense>
    <v-list-item>
      <v-list-item-content>
        <v-img
          v-if="selectedProgram.logo_image_url"
          :src="selectedProgram.logo_image_url"
          height="40"
          contain
        />
        <span v-else>
          {{ selectedProgram.name }}
        </span>
      </v-list-item-content>
      <v-btn icon @click.stop="$emit('close')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider />

    <v-list-group
      v-for="(group, i) in displayedGroups"
      :key="i"
      :prepend-icon="group.icon"
      :value="group.isOpen"
      no-action
    >
      <template #activator>
        <v-list-item-content>
          <v-list-item-title>{{ group.title }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(item, j) in group.children"
        :key="j"
        nuxt
        :to="item.to"
        :two-line="!!item.subtitle"
      >
        <v-list-item-avatar v-if="item.icon">
          <v-icon v-text="item.icon" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-if="item.subtitle">
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <!-- <v-list-item
      v-if="tester.test(managerMenu.to)"
      nuxt
      :to="managerMenu.to"
      color="blue darken-2"
    >
      <v-list-item-avatar v-if="managerMenu.icon" class="ml-n2">
        <v-icon v-text="managerMenu.icon" />
      </v-list-item-avatar>
      <v-list-item-content class="ml-2">
        <v-list-item-title v-text="managerMenu.title" />
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { filter } from 'lodash-es'

import { createTester } from '~/utils/functions/adminHasRights'

export default {
  computed: {
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', [
      'isPurcheaseAdmin',
      'isScanobarAdmin',
      'permittedActions',
      'hasReadContentRight'
    ]),

    tester() {
      return createTester({
        isPurcheaseAdmin: this.isPurcheaseAdmin,
        isScanobarAdmin: this.isScanobarAdmin,
        permittedActions: this.permittedActions
      })
    },
    managerMenu() {
      return {
        title: this.$t('navigation.administrators.index'),
        to: '/administrators/managers',
        icon: 'mdi-account-group'
      }
    },
    groups() {
      return [
        {
          title: this.$t('navigation.metrics.index'),
          to: '/metrics',
          icon: 'mdi-speedometer',
          isOpen: this.$route.path.startsWith('/metrics'),
          children: [
            {
              title: this.$t('navigation.metrics.program'),
              to: '/metrics/program'
            },
            {
              title: this.$t('navigation.metrics.canals'),
              to: '/metrics/canals'
            }
          ]
        },
        {
          title: this.$t('navigation.users.index'),
          to: '/users',
          icon: 'mdi-account-multiple',
          isOpen: this.$route.path.startsWith('/users'),
          children: [
            {
              title: this.$t('navigation.users.management'),
              to: '/users/management',
              icon: 'mdi-account-cog'
            },
            {
              title: this.$t('navigation.users.fraud'),
              to: '/users/fraud',
              icon: 'mdi-shield-alert'
            },
            {
              title: this.$t('navigation.users.transfers'),
              to: '/users/transfers',
              icon: 'mdi-bank-transfer-out'
            },
            {
              title: this.$t('navigation.users.segments'),
              to: '/users/segments',
              icon: 'mdi-segment'
            }
          ]
        },
        {
          title: this.$t('navigation.animation.index'),
          to: '/animation',
          icon: 'mdi-animation',
          isOpen: this.$route.path.startsWith('/animation'),
          children: [
            {
              title: this.$t('navigation.animation.offers'),
              to: '/animation/offers',
              icon: 'mdi-offer'
            },
            {
              title: this.$t('navigation.animation.messages'),
              to: '/animation/messages',
              icon: 'mdi-comment'
            },
            {
              title: this.$t('navigation.animation.campaigns'),
              to: '/animation/campaigns',
              icon: 'mdi-rocket-launch'
            },
            {
              title: this.$t('navigation.animation.statsPushes.title'),
              subtitle: this.$t('navigation.animation.statsPushes.subtitle'),
              to: '/animation/stats_pushes',
              icon: 'mdi-chart-box-outline'
            }
          ]
        },
        {
          title: this.$t('navigation.programs.index'),
          to: '/programs',
          icon: 'mdi-card-bulleted',
          isOpen: this.$route.path.startsWith('/programs'),
          children: [
            {
              title: this.$t('navigation.programs.rules'),
              to: '/programs/rules',
              icon: 'mdi-ruler'
            },
            {
              title: this.$t('navigation.programs.management'),
              to: '/programs/management',
              icon: 'mdi-cog'
            }
          ]
        },
        {
          title: this.$t('navigation.sources.index'),
          to: '/sources',
          icon: 'mdi-origin',
          isOpen: this.$route.path.startsWith('/sources'),
          children: [
            {
              title: this.$t('navigation.sources.pointsOfSale'),
              to: '/sources/points-of-sale',
              icon: this.isScanobarAdmin ? 'mdi-glass-mug-variant' : 'mdi-store'
            },
            // this is shis, does not work...
            // {
            //   title: this.$t('navigation.sources.productsCollection'),
            //   to: '/sources/products-collection',
            //   icon: 'mdi-folder'
            // },
            {
              title: this.$t('navigation.sources.products'),
              to: '/sources/products',
              icon: 'mdi-cart'
            }
          ]
        }
      ]
    },
    displayedGroups() {
      return filter(this.groups, (group) => this.tester.test(group.to)).map(
        (group) => ({
          ...group,
          children: filter(group.children, (child) =>
            this.tester.test(child.to)
          )
        })
      )
    }
  }
}
</script>
